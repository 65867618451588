type props =
  {
    children?: React.ReactNode;
  };

export const ContentWrapper = ({ children }: props) => {
  return (
    <>      
      <div className="antialiased text-slate-500 bg-white pb-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <main>
            <article className="prose prose-slate max-w-3xl mx-auto pt-10 print:pt-0 xl:max-w-none xl:ml-0 xl:mr-[20rem] xl:pr-16">
              {children}
            </article>
          </main>
        </div>
      </div>
    </>
  );
}

