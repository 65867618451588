import { Loading } from "../Elements/Loading";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FormatTitle } from "../utilities/StringFormatter";
import { useLayoutEffect } from 'react';
import '../js/print.js';
import { NotFound } from "./NotFound";
import Category from "../Models/Category";


export const CategorySelection = () => {
  let params = useParams();
  const currentLocation = useLocation();

  const [HelpCenterData, setData] = useState<Category[]>();

  const [FetchFailed, SetFetchFailed] = useState<boolean>(false);

  // Set display name for product based on `params.product` 
  const productDisaplyName_Map: Record<string, string> = {
    'datahub': 'Datahub', // Datahub
    'myvisma': 'My Visma', // My Visma
    'vismahr': 'Visma HR', // Visma HR
    'vismaloen-finans': 'Visma Løn', // Visma Løn (financial)
    'vismaloen-standard': 'Visma Løn', // Visma Løn (standard)
    'vismatime': 'Visma Time', // Visma Time
  };
  // If `params.product` is not one of the specified values, `productDisaplyName` will be empty
  const productDisaplyName = params.product ? productDisaplyName_Map[params.product] : '';
  
  // Set Strapi collection API ID to use for product based on `params.product` 
  const product_API_ID_Suffix_Map: Record<string, string> = {
    'datahub': 'datahubs', // Datahub
    'myvisma': 'mvs', // My Visma
    'vismahr': 'vhrs', // Visma HR
    'vismaloen-finans': 'vls', // Visma Løn (financial)
    'vismaloen-standard': 'vls', // Visma Løn (standard)
    'vismatime': 'vts', // Visma Time
  };
  // If `params.product` is not one of the specified values, `product_API_ID` will be `undefined`
  const product_API_ID_Suffix = params.product ? product_API_ID_Suffix_Map[params.product] : undefined;
  
  useEffect(() => {
    var url = "https://strapi.help.vismaenterprise.dk/api/category-"+product_API_ID_Suffix+"/?populate=guide_"+product_API_ID_Suffix;

    fetch(url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      },
    )
      .then((response) => response.json())
      .then((dto) => {
        if (dto.data === undefined) {
          SetFetchFailed(true);
          return;
        }
        setData(dto.data)
      });
  }, [params, product_API_ID_Suffix]);

  useLayoutEffect(() => {
    document.title = FormatTitle(`${productDisaplyName}`);
  }, [HelpCenterData, productDisaplyName])

  if (FetchFailed) {
    return (<NotFound />)
  }

  if (!HelpCenterData) {
    return (<Loading />);
  }

  return (
    <div>
      <div className="flex flex-col p-16 space-y-4 place-items-center bg-slate-50 text-center">
        {params.product === "datahub" && ( <img className="w-16" src="/datahub.svg" alt="" /> )}
        {params.product === "myvisma" && ( <img className="w-16" src="/my-visma.svg" alt="" /> )}
        {params.product === "vismahr" && ( <img className="w-16" src="/visma-hr.svg" alt="" /> )}
        {params.product?.startsWith("vismaloen") && (
          <img className="w-16" src={"/visma-lon" + [(params.product?.endsWith("-finans")) ? "-finans" : ''] + ".svg"} alt="" /> // If finance type, use finance logo, otherwise use standard version
        )}
        {params.product === "vismatime" && ( <img className="w-16" src="/visma-time.svg" alt="" /> )}
        <h1 className="m-0 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight">Vejledning til {productDisaplyName}</h1>
        <label htmlFor="search-button" className="cursor-pointer flex self-center items-center w-full max-w-xs h-fit gap-2 py-3 pl-4 pr-7 leading-6 rounded-xl shadow-sm bg-white/75 text-slate-400 hover:text-slate-400 ring-1 ring-slate-900/10 hover:ring-slate-500/50">
          <svg width="24" height="24" fill="none" aria-hidden="true" className="shrink-0">
            <path d="m19 19-3.5-3.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="11" cy="11" r="6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle>
          </svg>
          <span className="line-clamp-1 text-start ">Søg alle vejledninger...</span>
        </label>
      </div>

      {/* sm:px-6 md:grid-cols-2 md:px-8 xl:grid-cols-3 xl:gap-y-10 */}
      <div className="
        mx-auto grid max-w-7xl grid-cols-1 gap-4 px-4 py-16
        sm:px-16 sm:gap-8
        lg:grid-cols-2
        xl:grid-cols-3 xl:max-w-screen-2xl
      ">
        {HelpCenterData.map((category) => {
          if (
            (params.product === "vismaloen-finans" && category.attributes["guide_"+product_API_ID_Suffix].data.some((item: any) => item.attributes.Finans === true)) ||
            (params.product === "vismaloen-standard" && category.attributes["guide_"+product_API_ID_Suffix].data.some((item: any) => item.attributes.Standard === true)) ||
            category.attributes["guide_"+product_API_ID_Suffix]
          ) {
            return (
              <a 
                key={category.id} 
                className="group overflow-hidden relative bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex sm:items-center hover:z-10 p-4 gap-4 hover:shadow-xl transition-all" 
                href={currentLocation.pathname + '/' + category.attributes.Name}
              >
                
                <span className="sm:absolute sm:-left-28 sm:w-48 sm:h-48 sm:rounded-full sm:shadow-lg sm:overflow-hidden sm:flex sm:text-2xl sm:justify-center sm:items-center">
                  <span className="sm:indent-28">{category.attributes.Ikon}</span>
                </span>
                <div className="self-start sm:pl-24 sm:relative sm:py-2">
                  <div className="text-slate-700 font-semibold text-sm sm:text-base group-hover:text-ve-cyan-500 transition-all">{category.attributes.Display_Name}</div>
                  <div className="text-slate-500 font-normal text-sm leading-relaxed mt-1 line-clamp-5 lg:line-clamp-2">{category.attributes.Description}</div>
                </div>
              </a>
            );
          }
          return null; // Add this line to explicitly return null for items that don't meet the condition
        })}
      </div>
    </div>
  );
}

export default CategorySelection;
