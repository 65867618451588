import { useLocation, useParams } from "react-router-dom";
import SearchWidget from "./SearchWidget";
import { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { extractTrueID } from '../utilities/numberUtils';


export const NavigationHeader = () => {
    const params = useParams();
    const location = useLocation();
    const [productName, setProductName] = useState("")
    const [categoryName, setCategoryName] = useState("");
    const [guideTitle, setGuideTitle] = useState("");

    // Set Strapi collection API ID to use for product based on `params.product` 
    const product_API_ID_Suffix_Map: Record<string, string> = {
        'datahub': 'datahubs', // Datahub
        'myvisma': 'mvs', // My Visma
        'vismahr': 'vhrs', // Visma HR
        'vismaloen-finans': 'vls', // Visma Løn (financial)
        'vismaloen-standard': 'vls', // Visma Løn (standard)
        'vismatime': 'vts', // Visma Time
    };
    // If `params.product` is not one of the specified values, `product_API_ID` will be `undefined`
    const product_API_ID_Suffix = params.product ? product_API_ID_Suffix_Map[params.product] : undefined;
    
    // Set the customer type name to display based on `params.product`
    const typeDisplayNameMap: Record<string, string> = {
        // 'vismaloen-standard': 'bruger',
        'vismaloen-finans': 'finans-bruger',
    };
    // If `params.product` is not one of the specified values, `typeDisplayName` will be `undefined`.
    const typeDisplayName = params.product ? typeDisplayNameMap[params.product] : undefined;

    useEffect(() => {
        const strapiEnpointBaseURL = "https://strapi.help.vismaenterprise.dk/api/";

        const fetchProductData = async () => {
            try {
                const response = await fetch(strapiEnpointBaseURL + "product-" + product_API_ID_Suffix + "?filters[Name][$eqi]=" + (params.product?.startsWith("vismaloen") ? "vismaloen" : params.product) + "&fields[0]=name&fields[1]=display_name&publicationState=live");
                const data = await response.json();
                if (!!data?.data?.length) {
                    setProductName(data.data[0].attributes.Display_Name);
                    console.log(productName);
                }
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        const fetchCategoryData = async () => {
            try {
                const response = await fetch(strapiEnpointBaseURL + "category-" + product_API_ID_Suffix + "?filters[Name][$eqi]=" + params.category + "&fields[0]=name&fields[1]=display_name&publicationState=live");
                const data = await response.json();
                if (data.data.length > 0) {
                    setCategoryName(data.data[0].attributes.Display_Name);
                }
            } catch (error) {
                console.error("Error fetching category data:", error);
            }
        };

        const fetchGuideData = async () => {
            try {
                const response = await fetch(strapiEnpointBaseURL + "guide-" + product_API_ID_Suffix + "?filters[id][$eqi]=" + extractTrueID(params.guide ?? '') + "&fields[0]=slug&fields[1]=Title&publicationState=live");
                const data = await response.json();
                if (data.data.length > 0) {
                    setGuideTitle(data.data[0].attributes.Title);
                }
            } catch (error) {
                console.error("Error fetching guide data:", error);
            }
        };

        // Fetch product data if :product exists in the URL
        if (params.product) {
            fetchProductData();
        }

        // Fetch category data if :category exists in the URL
        if (params.category) {
            fetchCategoryData();
        }

        // Fetch guide data if :guide exists in the URL
        if (params.guide) {
            fetchGuideData();
        }
    }, [params.product, params.category, params.guide, productName, product_API_ID_Suffix]);

    return (
        <menu className="print:hidden sticky top-0 z-40 w-full backdrop-blur flex-none text-slate-700 transition-colors duration-500 border-b border-slate-900/10 bg-ve_pearl_bush bg-ve_pearl_bush/60 p-4">
            <div className="flex max-w-7xl mx-auto gap-x-4 justify-between text-sm sm:pl-2 lg:px-8">
                <nav className="flex items-center gap-x-2 min-w-0 leading-6 font-medium whitespace-nowrap overflow-x-auto">
                    <a
                        className={
                            "flex w-7 shrink items-center overflow-hidden lg:w-auto" +
                            (params.product ? " font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20" : "")
                        }
                        href="/">
                        <img className="lg:mr-3 h-[18px] object-cover object-left" src="/visma-enterprise.svg" alt="" />
                        <span className="sr-only">Visma Enterprise's hjælpecenters forside</span>
                        Hjælpecenter
                    </a>
                    {/* Breadcrumb: Product */}
                    {
                        <>
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 opacity-50" />
                            {location.pathname === (`/${params.product}`) ? (
                                <span>{productName} {typeDisplayName && <>({typeDisplayName})</>}</span>
                            ) : (
                                <a className="font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20" href={`/${params.product}`}>
                                    {productName} {typeDisplayName && <>({typeDisplayName})</>}
                                </a>
                            )}
                        </>
                    }
                    {/* Breadcrumb: Category */}
                    {params.category && categoryName &&
                        <>
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 opacity-50" />
                            {location.pathname === (`/${params.product}/${params.category}`) ? (
                                <span>{categoryName}</span>
                            ) : (
                                <a className="font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20" href={`/${params.product}/${params.category}`}>
                                    {categoryName}
                                </a>
                            )}
                        </>
                    }
                    {/* Breadcrumb: Guide */}
                    {params.guide && guideTitle &&
                        <>
                            <ChevronRightIcon className="hidden xl:inline-flex h-5 w-5 flex-shrink-0 opacity-50" />
                            {location.pathname === (`/${params.product}/${params.category}/${params.guide}`) ? (
                                <span className="hidden xl:inline-flex">{guideTitle}</span>
                            ) : (
                                <a className="hidden xl:inline-flex font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20" href={`/${params.product}/${params.category}/${params.guide}`}>
                                    {guideTitle}
                                </a>
                            )}
                        </>
                    }
                </nav>
                <div className="ml-auto flex items-center lg:items-center lg:justify-end">
                    <a className="hidden mr-6 lg:flex lg:flex-1 font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20" href="https://community.visma.com">Community</a>
                    {params.product &&
                        <SearchWidget searchInLabel={productName} />
                    }
                </div>
            </div>
        </menu>
    );
};
