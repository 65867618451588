
export const Loading = () => {

    return (
        <div className="absolute flex inset-0 flex-col items-center justify-center">
          <span className="mb-12 inline-flex items-center text-sm font-semibold text-slate-500">
            <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Indlæser...
          </span>
  
          <div className="w-full max-w-xs rounded-lg bg-white p-8 shadow-lg ring-1 ring-slate-900/5">
            <div className="flex animate-pulse space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="space-y-3">
                  <div className="col-span-1 h-1 w-1/12 rounded bg-slate-200"></div>
                  <div className="h-8 w-2/5 rounded bg-slate-200"></div>
                  <div className="h-3 w-3/5 rounded bg-slate-200"></div>
                </div>
                <div className="space-y-3">
                  <div className="w-3/3 h-2 rounded bg-slate-200"></div>
                  <div className="h-2 w-2/3 rounded bg-slate-200"></div>
                </div>
                <div className="divide-y-2 divide-slate-100">
                  <div className="space-y-3 py-4">
                    <div className="h-2 w-3/5 rounded bg-slate-200"></div>
                  </div>
                  <div className="space-y-3 py-4">
                    <div className="h-2 w-3/5 rounded bg-slate-200"></div>
                  </div>
                  <div className="space-y-3 py-4">
                    <div className="h-2 w-3/5 rounded bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};