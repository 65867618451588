export function padNumberWithZeros(number: number, minDigits: number = 5): string {
  return String(number).padStart(minDigits, '0');
}

// Extract the true ID by considering the first 5 characters of the input string and remove leading zeros
export function extractTrueID(input: string): string {
  // Extract the first 5 characters
  const first5Chars = input.substr(0, 5);

  // Remove leading zeros
  const trueID = parseInt(first5Chars, 10).toString();

  return trueID;
}