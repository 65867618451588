import { useEffect, useRef } from "react";
import SearchModal from "./SearchModal";
import algoliasearch from "algoliasearch";

// Algolia API keys (https://dashboard.algolia.com/account/api-keys)
const searchClient = algoliasearch(
  "ZQMCUW8QJU", // Application ID
  "5bb8b538195db0effe0e1499438649a2" // Search-Only API Key
);

type SearchWidgetProps = {
  searchInLabel?: string;
};


function SearchWidget({searchInLabel} : SearchWidgetProps) {
    const searchDialog = useRef<HTMLDialogElement>(null);

    // this makes it so you can remove the modal by clicking outisde its box
    useEffect(() => {
      const modal = searchDialog.current;
      if (modal) {
        modal.addEventListener("click", e => {
          const dialogDimensions = modal?.getBoundingClientRect();
          if (dialogDimensions && (
            e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom
          )) {
            modal?.close();
          }
        })
      }
    })
    
    const openDialog = () => {
      if (searchDialog.current) {
        searchDialog.current.showModal();
      }
    }

    return (
    <>
      <button 
        onClick={openDialog} 
        id="search-button"
        className="pointer-events-auto flex self-center items-center w-auto h-fit gap-1 md:py-1.5 md:pl-2 md:pr-3.5 text-sm leading-6 rounded-lg md:shadow-sm md:bg-white/75 text-slate-500 hover:text-slate-700  md:text-slate-400 md:hover:text-slate-400 md:ring-1 md:ring-slate-900/10 md:hover:ring-slate-500/50"
      >
        <svg width="24" height="24" fill="none" aria-hidden="true" className="shrink-0">
          <path d="m19 19-3.5-3.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="11" cy="11" r="6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle>
        </svg>
        <span className="line-clamp-1 text-start sr-only md:not-sr-only">Søg vejledning{searchInLabel ? ` til ${searchInLabel}` : ""}</span>
      </button>
      <dialog 
        ref={searchDialog} 
        className="open:flex open:flex-col w-full sm:max-w-xl mt-4 xl:mt-16 rounded-2xl drop-shadow-sm backdrop:backdrop-blur-sm backdrop:bg-ve-blue-900/25"
      >
        <SearchModal searchClient={searchClient} dialogElement={searchDialog.current}/>
      </dialog>
    </>);
}

export default SearchWidget;