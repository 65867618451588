import { Routes, Route, Outlet } from "react-router-dom";
import './css/index.css';
import { CustomerTypeSelection } from "./Pages/CustomerTypeSelection";
import { ProductSelection } from "./Pages/ProductSelection";
import { CategorySelection } from "./Pages/CategorySelection";
import { GuideSelection } from "./Pages/GuideSelection";
import GuidePost from "./Pages/GuidePost";
import { NotFound } from "./Pages/NotFound";
import { NavigationHeader } from "./Elements/NavigationHeader";
import CookieBanner from "./Elements/CookieBanner";


export default function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<ProductSelection />} />
        <Route path=":product" element={<CategorySelection />} />
        <Route path=":product/:category" element={<GuideSelection />} />
        <Route path=":product/:category/:guide" element={<GuidePost />} />
        <Route path="vismaloen" element={<CustomerTypeSelection />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
    </>
  );
}

function Layout() {
  return (
    <>
      <NavigationHeader />
      <Outlet />
      <CookieBanner />
    </>
  );
}

