/**
 * Handle collapsibles.
 * 
 * Based on the open and closed states of the collapsible sections, 
 * handle what's to be being printed, and revert open and closed states
 * to what where pre print.
 */

// When the user wants to print the page
window.onbeforeprint = function () {

    // Get all the `<details>` elements on the page
    var detailsElements = document.getElementsByTagName("details");
    // Count all the opened `<details>` elements on the page
    var openDetailsElementsCount = document.querySelectorAll("details[open]").length;


    // If all `<detail>`s are closed, open them
    if (openDetailsElementsCount === 0) {
        // Loop through each `<details>` element and set the 'open' attribute
        for (var i = 0; i < detailsElements.length; i++) {
            detailsElements[i].setAttribute("open", "");
        }
    }
    // Otherwise, leave as is, but hide "collapsed" sections on print
    else {
        // Hide each "collapsed" `<section>`
        for (let i = 0; i < detailsElements.length; i++) {
            if (!detailsElements[i].open) {
                detailsElements[i].parentElement.classList.add("print:hidden");
            }
        }
        // And hide the ToC, too
        document.getElementById("toc").classList.add("print:hidden");
    }


    // When the user finishes printing
    window.onafterprint = function () {

        // Get all the `<details>` elements on the page
        var detailsElements = document.getElementsByTagName("details");
        // Count all the opened `<details>` elements on the page
        var openDetailsElementsCount = document.querySelectorAll("details[open]").length;

        // Un-hide each "collapsed" `<section>`
        for (var i = 0; i < detailsElements.length; i++) {
            if (!detailsElements[i].open) {
                detailsElements[i].parentElement.classList.remove("print:hidden");
            }
        }
        // And un-hide the ToC, too
        document.getElementById("toc").classList.remove("print:hidden");

        // If all `<detail>`s are open, close them again
        if (openDetailsElementsCount === detailsElements.length) {
            // Loop through each `<details>` element again and remove the 'open' attribute
            for (let i = 0; i < detailsElements.length; i++) {
                detailsElements[i].removeAttribute("open");
            }
        }
    };
};


/**
 * Collect print stats.
*
* Track that the user initiated a print by sending a custom
* event to multiple Google Analytics properties.
*/
function gtag() { window.dataLayer.push(arguments); } // Initialize Google Analytics
(function () {
    var afterPrint = function () {
        gtag('event', 'print_page', {
            'screen_name': document.title,
            'screen_url': window.location.href,
            'send_to': [
                'G-HESEFPF206', // Product Team
                'G-VQRZVGZPXR', // Løn og HR
            ],
        });
    };

    if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addEventListener('change', function (mql) {
            if (!mql.matches) {
                afterPrint();
            }
        });
    }

    window.onafterprint = afterPrint;
})();
