import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { BoxType, InfoBox } from "./InfoBox";
import { generateAnchorLink } from "../utilities/StringFormatter";
import VideoEmbed from "./VideoEmbed";

type SectionProps = {
  section: any;
};

export const Section = ({section}: SectionProps) => {
  let anchorLink = generateAnchorLink(section.Headline);
  return (
    <>
      <section>
        <details className="group py-4">
          {/* TODO: Make section anchor (`id`) semantic. Below is a draft suggestion */}
          {/*       {section.Headline.toLowerCase().replace(/[^\w\sÀ-ÿ]/g, '').replace(/[^\w\s-]/g, '').replace(/[\s]+/g, '-').replace(/[-]+/g, '-')} */}
          <summary className="flex w-full cursor-pointer justify-between text-left text-base font-semibold leading-7 text-slate-900 [&::-webkit-details-marker]:hidden scroll-m-24" id={anchorLink}>
            <h2 className="group-open:text-ve-cyan-600 m-0 text-base sm:text-2xl">
              {section.Headline}
            </h2>
            <span className="inline-flex ml-4 gap-x-4 items-center">
              <button className="print:hidden hidden group-open:flex print:group-open:hidden items-center gap-x-1 flex-shrink-0 px-2 py-1 rounded no-underline ring-2 ring-gray-300 ring-opacity-25 drop-shadow-sm bg-white text-gray-600 text-sm font-medium hover:bg-gray-50 active:bg-gray-100 active:drop-shadow-none" onClick={window.print}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M5 2.75C5 1.784 5.784 1 6.75 1h6.5c.966 0 1.75.784 1.75 1.75v3.552c.377.046.752.097 1.126.153A2.212 2.212 0 0118 8.653v4.097A2.25 2.25 0 0115.75 15h-.241l.305 1.984A1.75 1.75 0 0114.084 19H5.915a1.75 1.75 0 01-1.73-2.016L4.492 15H4.25A2.25 2.25 0 012 12.75V8.653c0-1.082.775-2.034 1.874-2.198.374-.056.75-.107 1.127-.153L5 6.25v-3.5zm8.5 3.397a41.533 41.533 0 00-7 0V2.75a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25v3.397zM6.608 12.5a.25.25 0 00-.247.212l-.693 4.5a.25.25 0 00.247.288h8.17a.25.25 0 00.246-.288l-.692-4.5a.25.25 0 00-.247-.212H6.608z" clipRule="evenodd" />
                </svg>
                Print åbne sektioner
              </button>
              <svg className="print:hidden h-6 w-6 flex-none stroke-slate-700 group-open:stroke-ve-cyan-500" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M18 12H6"></path>
                <path className="group-open:hidden" d="M12 6v12"></path>
              </svg>
            </span>
          </summary>
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{section.Introduction ?? ""}</ReactMarkdown>
            {
              section.Information != null && section.Information.trim().length > 0 ?
                <InfoBox boxType={BoxType.Information}>
                  <ReactMarkdown>{section.Information}</ReactMarkdown>
                </InfoBox>
                : ""
            }
            {section.Warnings != null ? <InfoBox boxType={BoxType.Warning}><ReactMarkdown>{section.Warnings}</ReactMarkdown></InfoBox> : ""}
            <VideoEmbed embedId={section.Video} />
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{section.Content ?? ""}</ReactMarkdown>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{section.Conclusion ?? ""}</ReactMarkdown>
            {section.Example1 != null && section.Example1.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{section.Example1}</ReactMarkdown></InfoBox> : ""}
            {section.Example2 != null && section.Example2.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{section.Example2}</ReactMarkdown></InfoBox> : ""}
            {section.Example3 != null && section.Example3.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{section.Example3}</ReactMarkdown></InfoBox> : ""}
            {section.SubSection &&
              section.SubSection.map((subSection: any) => (
                <div key={subSection.id}>
                  <h3>{subSection.Headline}</h3>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{subSection.Introduction ?? ""}</ReactMarkdown>
                  {
                    subSection.Information != null && subSection.Information.length > 0?
                      <InfoBox boxType={BoxType.Information}>
                        <ReactMarkdown>{subSection.Information}</ReactMarkdown>
                      </InfoBox>
                      : ""
                  }
                  {subSection.Warnings != null ? <InfoBox boxType={BoxType.Warning}><ReactMarkdown>{subSection.Warnings}</ReactMarkdown></InfoBox> : ""}
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{subSection.Content ?? ""}</ReactMarkdown>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{subSection.Conclusion ?? ""}</ReactMarkdown>

                  {/* <p>ExampleHeadline1: {subSection.ExampleHeadline1 ?? "N/A"}</p> */}
                  {/* <p>ExampleHeadline2: {subSection.ExampleHeadline2 ?? "N/A"}</p> */}
                  {/* <p>ExampleHeadline3: {subSection.ExampleHeadline3 ?? "N/A"}</p> */}

                  {subSection.Example1 != null && subSection.Example1.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{subSection.Example1}</ReactMarkdown></InfoBox> : ""}
                  {subSection.Example2 != null && subSection.Example2.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{subSection.Example2}</ReactMarkdown></InfoBox> : ""}
                  {subSection.Example3 != null && subSection.Example3.trim().length > 0 ? <InfoBox boxType={BoxType.Example}><ReactMarkdown>{subSection.Example3}</ReactMarkdown></InfoBox> : ""}
                </div>
              ))}
            {section.Conclusion ?? ""}
          </div>
        </details>
      </section>
    </>
  );
};