import { ContentWrapper } from "../Elements/ContentWrapper";
import { Loading } from "../Elements/Loading";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FormatString as FormatStrapiUrl } from "../utilities/StringFormatter";
import '../js/print.js';
import { NotFound } from "./NotFound";
import Category from "../Models/Category";
import qs from "qs";
import { Helmet } from "react-helmet";
import { padNumberWithZeros as paddedId } from '../utilities/numberUtils';

export const GuideSelection = () => {
  let params = useParams();
  const currentLocation = useLocation();

  const [HelpCenterData, setData] = useState<Category>();

  const [FetchFailed, SetFetchFailed] = useState<boolean>(false);

  // Set Strapi collection API ID to use for product based on `params.product` 
  const product_API_ID_Suffix_Map: Record<string, string> = {
    'datahub': 'datahubs', // Datahub
    'myvisma': 'mvs', // My Visma
    'vismahr': 'vhrs', // Visma HR
    'vismaloen-finans': 'vls', // Visma Løn (financial)
    'vismaloen-standard': 'vls', // Visma Løn (standard)
    'vismatime': 'vts', // Visma Time
  };
  // If `params.product` is not one of the specified values, `product_API_ID` will be `undefined`
  const product_API_ID_Suffix = params.product ? product_API_ID_Suffix_Map[params.product] : undefined;
  
  // Set exact API filtering name based on `params.product` 
  const typeDisplayNameMap: Record<string, string> = {
    'vismaloen-standard': 'Standard',
    'vismaloen-finans': 'Finans',
  };
  // If `params.product` is not one of the specified values, `typeDisplayName` will be `undefined`.
  const typeDisplayName = params.product ? typeDisplayNameMap[params.product] : undefined;

  useEffect(() => {
    const urlParams = {
      filters: {
        Name: {
          $eqi: params.category,
        },
      },
      fields: ['name', 'description', 'display_name'],
      populate: {
        ["guide_"+[`${product_API_ID_Suffix}`]]: {
          fields: [
            'id', 'title', 'description', 'slug', 
            'standard', 'finans', 
          ],
          filters: {
            [`${typeDisplayName}`]: {
              $eq: true,
            },
          },
        },
      },
      publicationState: 'live',
    };

    const queryString = decodeURIComponent(qs.stringify(urlParams)); 

    // :type vismaloen :category
    var baseUrl = 'https://strapi.help.vismaenterprise.dk/api/category-'+product_API_ID_Suffix+'?{0}';
    var url = FormatStrapiUrl(
      baseUrl,
      queryString,
    );

    if (process.env.NODE_ENV === 'development') {
      console.log('Used URL: '+url); // Log the URL to the console in development mode
    }

    fetch(url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      },
    )
      .then((response) => response.json())
      .then((dto) => {
        if (dto.data === undefined) {
          SetFetchFailed(true);
          return;
        }
        setData(dto.data[0])
      });
  }, [params, product_API_ID_Suffix, typeDisplayName]);

  if (FetchFailed) {
    return (<NotFound />)
  }

  if (!HelpCenterData) {
    return (<Loading />);
  }

  return (
    <div>

    <ContentWrapper>
      <div>
        <Helmet>
          <title>
            {HelpCenterData.attributes.Display_Name} {
              ((params.product === "datahub") && ("i datahubben")) || 
              ((params.product === "myvisma") && ("i My Visma")) ||
              ((params.product === "vismahr") && ("i Visma HR")) || 
              ((params.product === "vismatime") && ("i Visma HR")) || 
              ((params.product?.startsWith("vismaloen")) && ("i Visma Løn"))
            }
          </title>
          <meta name="description" content={HelpCenterData.attributes.Description} />
        </Helmet>
      </div>
      <header className="flex flex-col">
        <h1 className="not-prose m-0 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight">{HelpCenterData.attributes.Display_Name}</h1>
      </header>
      <p className="not-prose mt-2 text-base sm:text-xl text-slate-700">
        {HelpCenterData.attributes.Description}
      </p>

      <dl className="mb-4 md:mb-16">
        {HelpCenterData.attributes["guide_"+product_API_ID_Suffix].data.map((guide: any) => {
          return <div key={guide.id}>
            <a className="group block no-underline hover:bg-slate-50 hover:ring-8 ring-slate-50 rounded" href={
              currentLocation.pathname 
              + '/'
              + paddedId(guide.id)
              + '-'
              + guide.attributes.Slug
            }>
              <dt>
                <h2 className="text-sm sm:text-lg text-slate-700 group-hover:text-ve-cyan-500">
                  {guide.attributes.Title}
                </h2>
              </dt>
              <dd className="text-xs leading-relaxed sm:text-base font-normal text-slate-500 line-clamp-6">
                {guide.attributes.Description}
              </dd>
            </a>
          </div>
        })}
      </dl>
    </ContentWrapper>
    
    </div>
  );
}

export default GuideSelection;
