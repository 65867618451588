import { PrintLogoAndTextProps } from "../Models/PrintLogoAndText";

function PrintLogoAndText(props: PrintLogoAndTextProps) {

  // Set display name for product based on `params.product` 
  const productDisaplyName_Map: Record<string, string> = {
    'datahub': 'Datahub', // Datahub
    'myvisma': 'My Visma', // My Visma
    'vismahr': 'Visma HR', // Visma HR
    'vismaloen-finans': 'Visma Løn', // Visma Løn (financial)
    'vismaloen-standard': 'Visma Løn', // Visma Løn (standard)
    'vismatime': 'Visma Time', // Visma Time
  };
  // If `params.product` is not one of the specified values, `productDisaplyName` will be empty
  const productDisaplyName = props.product ? productDisaplyName_Map[props.product] : '';

  return (
    <span className="hidden print:inline-flex items-center gap-2 w-fit p-2 px-3 rounded-lg font-semibold text-slate-500 bg-slate-100">
      <img
        className="w-[1.25em] m-0"
        src={
          (props.product === "datahub" && ("/datahub.svg")) ||
          (props.product === "myvisma" && ("/my-visma.svg")) ||
          (props.product === "vismahr" && ("/visma-hr.svg")) ||
          (props.product?.startsWith("vismaloen") && ("/visma-lon" + [(props.product?.endsWith("finans")) ? "-finans" : ''] + ".svg")) ||
          (props.product === "vismatime" && ("/visma-time.svg")) || 
          '' // If `params.product` is not one of the specified values, `productDisaplyName` will be empty
        }
        alt=""
      />
      Vejledning til {productDisaplyName}
    </span>
  )
}

export default PrintLogoAndText;
