import slugify, { Options } from "@sindresorhus/slugify";

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, `${val[index]}`);
  }
  return str;
}

export function FormatTitle(str : string){
  // TODO: Replace "Visma Hjælpecenter" with "Hjælp til {product} ({product.type})"
  return `Hjælp til ${str}`;
}

export function generateAnchorLink(name: string): string {
  const options: Options = {
    separator: '-',
    decamelize: false,
    customReplacements: [
      ['&', 'og'],
      ['æ', 'a'],
      ['Æ', 'A'],
    ],
  };

  return slugify(name, options);
}