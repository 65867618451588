import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentWrapper } from "../Elements/ContentWrapper";
import { Loading } from "../Elements/Loading";
import { Section } from "../Elements/Section";
import Guide from "../Models/Guide";
import { FormatString as FormatStrapiUrl, generateAnchorLink } from "../utilities/StringFormatter";
import { BoxType, InfoBox } from "../Elements/InfoBox";
import { Feedback } from "../Elements/Feedback";
import { NotFound } from "./NotFound";
import { useLayoutEffect } from 'react';
import '../js/print.js';
import PrintLogoAndText from "../Elements/PrintLogoAndText";
import VideoEmbed from "../Elements/VideoEmbed";
import { PaperClipIcon, ChatBubbleBottomCenterTextIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Helmet } from "react-helmet";
import { extractTrueID } from '../utilities/numberUtils';

export const GuidePost = () => {

  let params = useParams();
  const [HelpCenterData, setData] = useState<Guide>();
  const [FetchFailed, SetFetchFailed] = useState<boolean>(false);
  const [canonicalProductType, setCanonicalProductType] = useState<string>('');
  const guideID = extractTrueID(params.guide ?? ''); // Use an empty string if `params.guide` is falsy

  // Set Strapi collection API ID to use for product based on `params.product` 
  const product_API_ID_Suffix_Map: Record<string, string> = {
    'datahub': 'datahubs', // Datahub
    'myvisma': 'mvs', // My Visma
    'vismahr': 'vhrs', // Visma HR
    'vismaloen-finans': 'vls', // Visma Løn (financial)
    'vismaloen-standard': 'vls', // Visma Løn (standard)
    'vismatime': 'vts', // Visma Time
  };
  // If `params.product` is not one of the specified values, `product_API_ID` will be `undefined`
  const product_API_ID_Suffix = params.product ? product_API_ID_Suffix_Map[params.product] : undefined;

  const [location, setLocation] = useState('');

  useEffect(() => {
    var currentLocation = `${params.category}_${params.guide}`;
    if (currentLocation === location) {
      return;
    }

    setLocation(currentLocation);
    var baseUrl = `https://strapi.help.vismaenterprise.dk/api/guide-`+product_API_ID_Suffix+`?{0}&populate=deep`;
    var url = FormatStrapiUrl(
      baseUrl,
      `filters[id][$eqi]=${guideID!}`
    );

    if (process.env.NODE_ENV === 'development') {
      console.log('Used URL: '+url); // Log the URL to the console in development mode
    }

    fetch(url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      },
    )
      .then((response) => response.json())
      .then((dto) => {
        if (dto.data.length === 0) {
          SetFetchFailed(true);
          return;
        }
        setData(dto.data[0])

        setCanonicalProductType(
          dto.data[0].attributes.Standard ? "standard" :
          dto.data[0].attributes.Finans ? "finans" :
          params.category! // fallback to current category
        );
      });
    }, [params, guideID, location, product_API_ID_Suffix]);
    
  useLayoutEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const details = document.querySelector(hash);
      if (details) {
        const parentElement = details.parentElement;
        details.scrollIntoView({ behavior: 'auto' });
        if (parentElement) {
          parentElement.setAttribute('open', '');
        }
      }
    }
  }, [HelpCenterData])


  if (FetchFailed) {
    return (<NotFound />)
  }

  if (!HelpCenterData) {
    return (<Loading/>) ;
  }

  return (
    <>
    <ContentWrapper>
      <div>
        <Helmet>
          <title>{HelpCenterData.attributes.Title}</title>
          <meta name="description" content={HelpCenterData.attributes.Description} />
          {
            // If the canonical category or product type is different from the current route, render a `<link>` element with a canonical URL
            (
              HelpCenterData.attributes["category_"+product_API_ID_Suffix].data![0].attributes.Name !== params.category! || 
              (params.product?.startsWith("vismaloen") && !params.product?.endsWith(canonicalProductType))
            ) && (
              // Render a `<link>` element with a canonical URL
              <link
                rel="canonical"
                href={window.location.protocol + '//' + window.location.host + '/' + (params.product!?.startsWith("vismaloen") ? "vismaloen-" + canonicalProductType : params.product!) + '/' + HelpCenterData.attributes["category_" + product_API_ID_Suffix].data![0].attributes.Name + '/' + params.guide!}
              />
            )
          }
        </Helmet>
      </div>

      <header className="flex flex-col">
        <div className="mt-6 mb-3 flex items-start justify-between">
          <h1 className="mb-0">{HelpCenterData.attributes.Title}</h1>
          <button className="print:hidden flex items-center gap-x-1 flex-shrink-0 mt-2 px-2 py-1 rounded no-underline ring-2 ring-gray-300 ring-opacity-25 drop-shadow-sm bg-white text-gray-600 text-sm font-medium hover:bg-gray-50 active:bg-gray-100 active:drop-shadow-none" onClick={window.print} title="Er kun nogle sektioner åbne, bliver kun disse printet.">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M5 2.75C5 1.784 5.784 1 6.75 1h6.5c.966 0 1.75.784 1.75 1.75v3.552c.377.046.752.097 1.126.153A2.212 2.212 0 0118 8.653v4.097A2.25 2.25 0 0115.75 15h-.241l.305 1.984A1.75 1.75 0 0114.084 19H5.915a1.75 1.75 0 01-1.73-2.016L4.492 15H4.25A2.25 2.25 0 012 12.75V8.653c0-1.082.775-2.034 1.874-2.198.374-.056.75-.107 1.127-.153L5 6.25v-3.5zm8.5 3.397a41.533 41.533 0 00-7 0V2.75a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25v3.397zM6.608 12.5a.25.25 0 00-.247.212l-.693 4.5a.25.25 0 00.247.288h8.17a.25.25 0 00.246-.288l-.692-4.5a.25.25 0 00-.247-.212H6.608z" clipRule="evenodd" />
            </svg>
            Print
          </button>
        </div>
        <div className="order-first flex items-center gap-4 justify-between">
          <PrintLogoAndText product={params.product || ''} />
          <span className="not-prose text-sm">
            Opdateret
            {/* TODO: In `dateTime` output the Danish date in the format of `YYYY-MM-DD`  */}
            <time dateTime={new Date(HelpCenterData.attributes.updatedAt).toISOString().split('T')[0]}> {new Date(HelpCenterData.attributes.updatedAt).toLocaleDateString('da-DK', { day: 'numeric', month: 'long', year: 'numeric' })}</time>
          </span>
        </div>
      </header>

      <p className="not-prose mt-2 text-xl text-slate-700">{HelpCenterData.attributes.Description}</p>

      {/* If product is Visma Løn, show info */}
      {params.product?.startsWith("vismaloen") && (
        <div className="flex relative my-6 p-5 rounded break-inside-avoid-page bg-ve-blue-100 border-4 border-transparent print:border-ve-blue-100 marker:text-ve-blue-950/20 text-ve-blue-950">
          <span className="translate-y-0.5 mr-2 text-2xl">
            <InformationCircleIcon className="h-[1em] w-[1em] text-ve-blue-500" />
          </span>
          Hvis du har outsourcet lønbehandlingen til Visma, er denne vejledning kun til information, og du kan ikke foretage ændringer i Visma Løn, da du kun har læseadgang til lønsystemet.
        </div>
      )}
      
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{HelpCenterData.attributes.Introduction}</ReactMarkdown>

      {
        HelpCenterData.attributes.Information != null && HelpCenterData.attributes.Information.trim().length > 0 ?
          <InfoBox boxType={BoxType.Information}>
            <ReactMarkdown>{HelpCenterData.attributes.Information}</ReactMarkdown>
          </InfoBox>
          : ""
      }

      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{HelpCenterData.attributes.Content}</ReactMarkdown>

      <VideoEmbed embedId={HelpCenterData.attributes.YouTubeVideoCode} />
      
      <div className="xl:fixed xl:z-20 xl:top-[69px] xl:bottom-0 xl:right-[max(0px,calc(50%-38rem))] xl:w-[20rem] xl:py-2 xl:overflow-y-auto xl:block">
        <div className="print:hidden flex items-center justify-center mt-8 p-3.5 rounded-full font-medium bg-gradient-to-br from-ve-cyan-50 to-ve-cyan-200 text-ve-cyan-700">
          <ChatBubbleBottomCenterTextIcon className="h-5 w-h-5 mr-1 text-current" />
          <span>
            Hvad synes du? <a href={`https://docs.google.com/forms/d/e/1FAIpQLSeyahHncT2fZnUkEmW5rYdAoq2hHYRr_bkvrHazvGobri94eA/viewform?usp=pp_url&entry.537358490=${encodeURIComponent(window.location.href)}&entry.1991306598=`} className="text-current underline decoration-ve-cyan-500/40 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20">Giv feedback</a>.
          </span>
        </div>

        <div id="toc">
          {HelpCenterData.attributes.Section.length > 1 && (
            <>
              <h2>Vejledningen indeholder</h2>
              <nav>
                <ul>
                  {HelpCenterData.attributes.Section.map((section: any) => {
                    const anchorLink = generateAnchorLink(section.Headline);
                    return (
                      <li key={section.id}>
                        <a
                          className="no-underline font-normal hover:underline hover:text-ve-cyan-500 underline-offset-4 decoration-4 hover:decoration-ve-cyan-500/20"
                          href={`#${anchorLink}`}
                        >
                          {section.Headline}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>

      <div className="divide-y-4 divide-slate-100 my-8">
        {HelpCenterData.attributes.Section.map((section: any) => (
          <Section key={`${section.Headline}`} section={section} />
        ))}
      </div>
      
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{HelpCenterData.attributes.Conclusion}</ReactMarkdown>

      {
        HelpCenterData.attributes.Attachments.data !== null && (
          <>
            <section>
              <h2 className="mt-8">Bilag</h2>
              <div className="text-sm text-gray-900 not-prose mb-12">
                <ul className="divide-y divide-gray-100 rounded border border-gray-200">
                  {
                    HelpCenterData.attributes.Attachments.data!.map((attachment) => (
                      <>
                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <a href={`/media/${attachment.attributes.hash}${attachment.attributes.ext}`} title={attachment.attributes.name} className="truncate font-medium hover:underline hover:text-ve-cyan-500 underline-offset-4 decoration-4 hover:decoration-ve-cyan-500/20">{attachment.attributes.name}</a>
                            </div>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a href={`/media/${attachment.attributes.hash}${attachment.attributes.ext}`} className="font-medium no-underline text-ve-cyan-600 hover:underline hover:text-ve-cyan-500 underline-offset-4 decoration-4 hover:decoration-ve-cyan-500/20">Vis</a>
                          </div>
                        </li>
                      </>
                    ))
                  }
                </ul>
              </div>
            </section>
          </>
        )
      }

      <Feedback />

    </ContentWrapper>
    </>
  );
};

export default GuidePost;
