import React from "react";
import "../css/App.css";
import PropTypes from "prop-types";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

type Props = {
  embedId: string;
};

const getVideoIdFromUrl = (url: string): string => {

  // Regular expression patterns for YouTube and Vimeo video URLs
  const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]+)/;
  const vimeoPattern = /^(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:video\/)?(\d+)/;

  // Check if it's a YouTube URL and extract the video ID
  const youtubeMatch = url.match(youtubePattern);
  if (youtubeMatch) {
    return youtubeMatch[1];
  }

  // Check if it's a Vimeo URL and extract the video ID
  const vimeoMatch = url.match(vimeoPattern);
  if (vimeoMatch) {
    return vimeoMatch[1];
  }

  return "";
};

const VideoEmbed = ({ embedId }: Props) => {
  if (!embedId) {
    return null; // If embedId is empty, don't render anything
  }

  const videoId = getVideoIdFromUrl(embedId);

  if (!videoId) {
    // If videoId couldn't be extracted, log an error message
    console.error("Couldn't embed video due to invalid URL");
    return (
      <div className="p-6 rounded bg-orange-100 text-orange-700">
        Vi kan desværre ikke vise denne video, da vi vist er kommet til lave en fejl. <br />
        Informér os venligst om dette via feedback-formularen, så retter vi det ASAP 😊
      </div>
    );
  }

  const isYouTube = embedId.includes("youtube.com") || embedId.includes("youtu.be");
  const videoSource = isYouTube
    ? `https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&color=white&`
    : `https://player.vimeo.com/video/${videoId}?dnt=1&title=0&byline=0&portrait=0&pip=1`;

  return (
    <>
      {
        isYouTube && (
          <div className="text-xs text-slate-400 mx-1.5 py-2">
            <InformationCircleIcon className="inline h-[1.25em] w-[1.25em] mr-1 opacity-70" />
            Når videoen afspilles, gemmer YouTube oplysninger om besøget på dette website. <a className="text-current hover:no-underline hover:text-ve-cyan-500 underline-offset-2 decoration-1 decoration-current/5" href="//youtube.com/t/privacy" target="_blank" rel="noopener noreferrer">YouTube's privatlivspolik</a>.
          </div>
        )
      }
      <iframe
        className="w-full aspect-video rounded-md shadow-md mb-6"
        src={videoSource}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        title="Video player"
      />
    </>
  );
};

VideoEmbed.propTypes = {
  embedId: PropTypes.string
};

export default VideoEmbed;
