import React, { useState } from "react";
import ReactGA from "react-ga4";

import Cookies from 'js-cookie';

function CookieBanner() {
  const [, setIsVisible] = useState<boolean>(true);

  // Check if we're in test env. If so, just activate test mode
  if (process.env.NODE_ENV !== 'production') {
    ReactGA.initialize('test', { testMode: true });
    return (null);
  };

  // Check if consent for tracking has been allowed
  if ((Cookies.get('consent_statistical') === "allowed")) {
    // Initialize trackers
    ReactGA.initialize(
      [
        {
          trackingId: 'G-HESEFPF206',
          gaOptions: { name: 'Product Team', }
        },
        {
          trackingId: 'G-VQRZVGZPXR',
          gaOptions: { name: 'Løn og HR' }
        }
      ]
    );
    return (null);
  }
  // Check if user hasn't decided
  else if (Cookies.get('consent_statistical') === undefined) {
    // Show banner for opt-in
    return (
      <div className="grid justify-items-stretch fixed inset-x-0 bottom-0 z-50 m-10 pointer-events-none">
        <div className="rounded-md p-8 flex justify-between gap-6 flex-col sm:flex-row justify-self-center backdrop-blur-lg border border-slate-500/10 bg-white bg-white/60 shadow-xl pointer-events-auto">
          <p>
            <b>Må vi bruge statistiske cookies?</b> Det giver os indsigt, så vi nemmere kan optimere designet og brugervenligheden. <br />
            <small className="opacity-70">Du kan læse mere i <a className="underline font-semibold decoration-1 decoration-slate-400 underline-offset-2 hover:decoration-2" href="https://visma-com.webflow.io/privacy-statement/denmark">Vismas privatlivspolitik</a>.</small>
          </p>
          <span className="inline-flex gap-4">
            <button className="bg-ve-cyan-500/20 hover:bg-ve-cyan-500/30 active:bg-ve-cyan-500/40 text-ve-cyan-900 px-3.5 py-2 rounded font-semibold w-auto flex-grow self-center" onClick={
              function () {
                Cookies.set('consent_statistical', 'allowed', { expires: 365 });
                setIsVisible(false);
              }
            }>
              Tillad
            </button>
            <button className="bg-slate-500/10 hover:bg-slate-500/20 active:bg-slate-500/30 text-slate-900 px-3.5 py-2 rounded font-semibold w-auto flex-grow self-center" onClick={
              function () {
                Cookies.set('consent_statistical', 'disallowed', { expires: 365 });
                setIsVisible(false);
              }
            }>
              Afvis
            </button>
          </span>
        </div>
      </div>
    );
  }
  // User has disallowed tracking
  else {
    return (null);
  }
}

export default CookieBanner;