import { Helmet } from "react-helmet";

export const CustomerTypeSelection = () => {
  return (
    <>
      <Helmet>
        <title>Hjælp til Visma Enterprises produkter</title>
      </Helmet>
      <div className="flex flex-col p-16 space-y-4 place-items-center bg-slate-50 text-center">
        <h1 className="m-0 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight">
          Få hjælp til vores produkter
        </h1>
        <p className="prose prose-slate text-xl">
          Hvilken kunde-type er du?
        </p>
      </div>
      <div className="flex flex-row flex-wrap place-content-center gap-x-16 gap-y-8 xl:gap-y-10 max-w-7xl mx-auto py-16 px-4 sm:px-6 md:px-8">
        <a className="group w-72 p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex flex-col items-center gap-6 hover:shadow-xl transition-all" href="/vismaloen-standard">
          <figure className="text-2xl text-center">
            <img className="w-16" src="./pictograms/user.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Bruger</h2>
            <p className="text-slate-500">For dig som bruger vores standard-løsninger</p>
          </div>
        </a>
        <a className="group w-72 p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex flex-col items-center gap-6 hover:shadow-xl transition-all" href="/vismaloen-finans">
          <figure className="text-2xl text-center">
            <img className="w-16" src="./pictograms/user-finance.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Finans-bruger</h2>
            <p className="text-slate-500">For dig der bruger vores finans-løsninger</p>
          </div>
        </a>
      </div>
    </>

  );
}

