import {
  Hits,
  Index,
  InstantSearch,
  Configure,
  SearchBox,
  Snippet,
} from "react-instantsearch";

import { useInstantSearch } from 'react-instantsearch-core';

//import type { Hit as AlgoliaHit } from "instantsearch.js/es/types";
import { SearchClient } from "algoliasearch";

import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router";

import { padNumberWithZeros as paddedId } from '../utilities/numberUtils';


type SearchProps = {
  searchClient: SearchClient,
  dialogElement: HTMLDialogElement | null
};

export function SearchModal({ searchClient, dialogElement} : SearchProps) {
  const params = useParams();
  
  // so we can check for links outside of help.vismaenterprise.dk
  const isExternalLink = (url: string) => {
    try {
      const { hostname } = new URL(url);
      return hostname !== "help.vismaenterprise.dk";
    } catch (error) {
      return false;
    }
  }

/* Save this for reference
  interface HitProps {
    hit: Hit<{
      Title: string;
      Description: string;
      Slug: string;
      category_vls__Name: string;
    }>;
  };
  */

  function closeModal() {
    dialogElement?.close();
  }

  
  function NoResultsBoundary({ children, fallback } : any) {
    const { results } = useInstantSearch();
  
    if (!results.__isArtificial && results.nbHits === 0) {
      return fallback;
    }
  
    return children;
  }

  function NoResults() {
    const { results } = useInstantSearch();
    var suggestionURL = `https://docs.google.com/forms/d/e/1FAIpQLScIJ6y4AY0BoW3W2cK4DQALmmJzjPOmQNBcwtIpJjxrF3OsZA/viewform?usp=pp_url&entry.537358490=${results.query}`;
  
    return (
      <div className="bg-slate-50 px-12 py-16 text-center">
        <h2 className="font-semibold text-slate-600">Ingen resultater fundet</h2>
        <p className="mt-2 text-sm leading-6 text-slate-400">Vi kan desværre ikke finde noget med det udtryk i øjeblikket. <br />Prøv at søge efter noget andet.</p>
          <p className="mt-2 text-sm leading-6 text-slate-400">Hvis du ikke kan finde det, du leder efter, så <a href={suggestionURL} className="font-medium underline decoration-ve-cyan-500/20 decoration-2 hover:text-ve-cyan-500 underline-offset-4 hover:decoration-4 hover:decoration-ve-cyan-500/20">kom med et forslag</a>.</p>
      </div>
    );
  }
  
  
  function HitFunction({ hit }: any) {
    return (
      // target="_blank" means it opens on a blank new tab in the browser
      <a href={"/"+params.product+"/"+hit.primary_category_slug+"/"+paddedId(hit.id)+"-"+hit.Slug} target={isExternalLink(hit.Slug) ? "_blank" : "_self"} rel="noreferrer" className="p-4 flex flex-col text-slate-700">
        <span>
          <Snippet
            hit={hit}
            attribute="Title"
            classNames={{
              // The root element of the widget
              root: "font-medium text-slate-900",
              // The highlighted parts
              highlighted: "bg-transparent text-ve-blue-500 underline underline-offset-4 decoration-4 decoration-ve-blue-200",
              // The non-highlighted parts
              nonHighlighted: "",
              // The separator elements between highlighted parts
              separator: "",
            }}
            />
          {isExternalLink(hit.Slug) && (
            <span className="ml-1" title="Åbner i en ny fane">
              <ArrowTopRightOnSquareIcon className="inline h-4 w-4 text-slate-400" />
            </span>
          )}
        </span>

        <Snippet
          hit={hit}
          attribute="Description"
          classNames={{
            // The root element of the widget
            root: "line-clamp-3 leading-relaxed pb-0.5 -mb-0.5 mt-2",
            // The highlighted parts
            highlighted: "bg-transparent  underline underline-offset-4 decoration-4 decoration-ve-blue-200",
            // The non-highlighted parts
            nonHighlighted: "",
            // The separator elements between highlighted parts
            separator: "",
          }}
        />
      </a>
    );
  };

  // Set Visma Løn customer types to use for faceting in the Algolia filter
  const type_capitalized_Map: Record<string, string> = {
    'vismaloen-standard': 'Standard',
    'vismaloen-finans': 'Finans',
  };
  // If `params.product` is not one of the specified values, `type_capitalized` will be `undefined`.
  const type_capitalized = params.product ? type_capitalized_Map[params.product] : undefined;

  return (
    <InstantSearch searchClient={searchClient} insights={true}>

      <header className="flex px-8 py-4 border-b gap-2">
        <SearchBox
          autoFocus
          id="search_input"
          placeholder="Søg vejledning"
          translations={{
            submitButtonTitle: "Søg",
            resetButtonTitle: "Nulstil søgning"
          }}
          submitIconComponent={({ classNames }) => (
            <label htmlFor="search_input">
              <MagnifyingGlassIcon className={classNames.submitIcon} />
            </label>
          )}
          classNames={{
            // The root element of the widget
            root: "flex-1 justify-start gap-2.5 placeholder:text-opacity-50",
            // The form element
            form: "flex gap-4",
            // The input element
            input: "grow focus:outline-none",
            // The submit button
            submit: "order-first",
            // The reset button
            reset: "hidden",
            // The loading indicator element
            loadingIndicator: "",
            // The submit icon
            submitIcon: "w-5 h-5 opacity-50",
            // The reset icon
            resetIcon: "",
            // The loading icon
            loadingIcon: "",
          }}
        />
        <button onClick={closeModal} className="px-1 shadow rounded ring-1 ring-slate-200 hover:ring-slate-300 font-mono text-sm">Luk</button>
      </header>

      <Configure
        filters={(
          params.product?.startsWith("vismaloen") // If Visma Løn ...
            ? "NOT publishedAt:'never' AND " + type_capitalized + ":true" // ... then exclude non-published posts, AND include only those of chosen customer type ...
            : "NOT publishedAt:'never'" // ... else, just exclude non-published posts.
        )}
        attributesToSnippet={[
          "Title:15",
          "Description:20"
        ]}
        snippetEllipsisText="…"
      />
      
      <Index indexName={
        "strapi_" 
        + (params.product?.startsWith("vismaloen") ? "vismaloen" : params.product)
        + (process.env.NODE_ENV === "production" ? "_prod" : "_test") // Only use prod. indices when in production, as to not those indices' stats in Algolia
      }>
        <NoResultsBoundary fallback={<NoResults />}>
          <Hits
            hitComponent={HitFunction}
            className="overflow-auto  xl:max-h-[60vh]"
            classNames={{
              // The widget’s root element
              root: "p-4",
              // The root element without results
              emptyRoot: "hidden",
              // The list of results
              list: "",
              // The list of items
              item: "hover:bg-ve-blue-100 rounded-xl",
            }}
          />
        </NoResultsBoundary>
      </Index>
    </InstantSearch>
  );
}

export default SearchModal;
