import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { AcademicCapIcon } from '@heroicons/react/24/solid'

export enum BoxType
{
    Information,
    Warning,
    Example
}

type Props = {
    children?: React.ReactNode;
    boxType : BoxType;
};

export const InfoBox = ({boxType, children} : Props) => {

function GetType()
{
    switch(boxType)
    {
        case(BoxType.Example):
        {
            return (
                <span className="absolute inline-flex items-center gap-1 top-0 left-0 text-sm font-medium bg-white border border-slate-300 px-3 py-1 rounded -translate-x-5 -translate-y-1/2">
                    <AcademicCapIcon className="h-4 w-4" />
                    {" Eksempel"}
                </span>
            );
        }
        case(BoxType.Information):
        {
            // TODO: Merge screen and print layout
            // Screen-layout
            // return <span className="absolute top-0 left-0 -translate-x-3 -translate-y-1/2 text-2xl"><InformationCircleIcon className="h-[1em] w-[1em] text-ve-blue-500" /></span>;
            // Print-layout
            return (
                <>
            <span className="translate-y-1.5 mr-2 text-2xl inline-block">
                <InformationCircleIcon className="h-[1em] w-[1em] text-ve-blue-500" /></span>
                {"Følgende omtales:"}<br />
                </>
            );
        }   
        case(BoxType.Warning):
        {
            return <span className="absolute top-0 left-0 -translate-x-3 -translate-y-1/2 text-2xl"><ExclamationTriangleIcon className="h-[1em] w-[1em] text-ve-orange-500" /></span>;
        }     
    }
}

function GetBackgroundColor()
{
    switch(boxType)
    {
        case(BoxType.Example):
        return "relative my-6 p-5 rounded break-inside-avoid-page bg-slate-100 border-4 border-blue-100";
        case(BoxType.Information):
        return "relative my-6 p-5 rounded break-inside-avoid-page bg-ve-blue-100 border-4 border-transparent print:border-ve-blue-100 marker:text-ve-blue-950/20 text-ve-blue-950";
        case(BoxType.Warning):
        return "relative my-6 p-5 rounded break-inside-avoid-page bg-ve-yellow-100 border-4 border-transparent print:border-ve-yellow-100 marker:text-ve-yellow-950/20 text-ve-yellow-950";
    }
}

return (
    <div className={GetBackgroundColor()}>
        {GetType()}
        {children}
    </div>
);
}